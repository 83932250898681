import ApplicationController from './application_controller';
import { post } from '@rails/request.js';

export default class extends ApplicationController {
  static values = {
    url: String
  }

  static targets = [
    'info'
  ]

  connect() {
    this.infoTarget.style.display = 'none';
  }

  toggle() {
    if (this.urlValue) {
      post(this.urlValue);
      
      this.infoTarget.style.display = 'flex';
      setTimeout(() => {
        this.infoTarget.style.display = 'none';
      }, 5000);
    }
  }
}