import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static values = {
    url: String
  }

  showChatHistory(event) {
    event.preventDefault();
    const chatHistoryFrame = `
      <div id="chat-history-frame" class="bg-mixlr-background border-0 fixed shadow-glow rounded-xl overflow-hidden flex flex-col h-[calc(100%-100px)] w-[320px] sm:w-[400px] right-4 top-[60px] z-[1000]">
        <div class="border-[0] border-b border-solid border-gray-100 h-[50px] px-4 flex items-center justify-between w-full">
          <button id="close-button" class="border-0 bg-mixlr-background m-0 p-0 cursor-pointer h-[20px] w-[20px]">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M3.61289944,2.20970461 L3.70710678,2.29289322 L12,10.585 L20.2928932,2.29289322 C20.6834175,1.90236893 21.3165825,1.90236893 21.7071068,2.29289322 C22.0675907,2.65337718 22.0953203,3.22060824 21.7902954,3.61289944 L21.7071068,3.70710678 L13.415,12 L21.7071068,20.2928932 C22.0976311,20.6834175 22.0976311,21.3165825 21.7071068,21.7071068 C21.3466228,22.0675907 20.7793918,22.0953203 20.3871006,21.7902954 L20.2928932,21.7071068 L12,13.415 L3.70710678,21.7071068 C3.31658249,22.0976311 2.68341751,22.0976311 2.29289322,21.7071068 C1.93240926,21.3466228 1.90467972,20.7793918 2.20970461,20.3871006 L2.29289322,20.2928932 L10.585,12 L2.29289322,3.70710678 C1.90236893,3.31658249 1.90236893,2.68341751 2.29289322,2.29289322 C2.65337718,1.93240926 3.22060824,1.90467972 3.61289944,2.20970461 Z"></path></svg>
          </button>
          <strong class="font-bold">Chat</strong>
          <div class="h-[20px] w-[20px]"></div>
        </div>
        <iframe src="${this.urlValue}" class="bg-mixlr-background border-0 h-full w-full">
      </div>
    `;
    this.element.insertAdjacentHTML('beforebegin', chatHistoryFrame);

    document.addEventListener('click', function (e) {
      const target = e.target.closest('#close-button');
      const frame = document.getElementById('chat-history-frame');
      if (target && frame) {
        frame.remove();
      }
    });
  }
}