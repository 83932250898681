import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static targets = [
    'recordingCheckbox',
    'selectToggle',
    'moreToggle',
    'deleteButton',
    'addCollection'
  ]

  static values = {
    publicUrl: String,
    onlyMeUrl: String,
    unlistedUrl: String,
    trashUrl: String,
    deleteUrl: String,
    assignToCollectionUrl: String,
    selectedRecordingIds: Array
  }

  connect() {
    if (this.hasMoreToggleTarget) {
      this.moreToggleTargets.forEach(m => m.setAttribute('disabled', true));
    }
    if (this.hasDeleteButtonTarget) {
      this.deleteButtonTarget.setAttribute('disabled', true);
    }

    document.addEventListener('turbo:frame-load', (event) => { 
      if (!event.target.matches('.recording-search__frame')) return;
      document.getElementsByClassName('wrapper')[0].scrollTo(0, 0);
    });
  }

  toggleCheckbox(event) {
    let setDisabled = true;
    if (event.currentTarget.checked) {
      this.moreToggleTargets.forEach(m => m.removeAttribute('disabled'));
      if (this.hasDeleteButtonTarget) {
        this.deleteButtonTarget.removeAttribute('disabled');
      }
      this.selectToggleTarget.innerText = 'Select none';
      this.selectedRecordingIdsValue = [...this.selectedRecordingIdsValue, event.currentTarget.value];
    } else {
      this.recordingCheckboxTargets.forEach(checkbox => {
        if (checkbox.checked) {
          setDisabled = false;
        };
      });
      if (this.recordingCheckboxTargets.every((checkbox) => !checkbox.checked)) {
        setDisabled = true;
      }
      if (setDisabled) {
        this.selectToggleTarget.innerText = 'Select all';
        this.moreToggleTargets.forEach(m => m.setAttribute('disabled', true));
        if (this.hasDeleteButtonTarget) {
          this.deleteButtonTarget.setAttribute('disabled', true);
        }
      }
      this.selectedRecordingIdsValue = this.selectedRecordingIdsValue.filter(
        id => id !== event.currentTarget.value
      );
    }
  }

  selectAll(event) {
    if (event.currentTarget.innerText === 'Select all') {
      event.currentTarget.innerText = 'Select none';
      this.recordingCheckboxTargets.forEach(checkbox => {
        checkbox.checked = true;
        this.selectedRecordingIdsValue = [...this.selectedRecordingIdsValue, checkbox.value];
        this.moreToggleTargets.forEach(m => m.removeAttribute('disabled'));
        if (this.hasDeleteButtonTarget) {
          this.deleteButtonTarget.removeAttribute('disabled');
        }
      });
    } else {
      event.currentTarget.innerText = 'Select all';
      this.recordingCheckboxTargets.forEach(checkbox => {
        checkbox.checked = false;
        this.moreToggleTargets.forEach(m => m.setAttribute('disabled', true));
        this.selectedRecordingIdsValue = [];
        if (this.hasDeleteButtonTarget) {
          this.deleteButtonTarget.setAttribute('disabled', true);
        }
      });
    }
  }

  setPublic(event) {
    event.target.form.action = this.publicUrlValue;
  }

  setOnlyMe(event) {
    event.target.form.action = this.onlyMeUrlValue;
  }

  setUnlisted(event) {
    event.target.form.action = this.unlistedUrlValue;
  }

  trash(event) {
    event.target.form.action = this.trashUrlValue;
  }

  delete(event) {
    event.target.form.action = this.deleteUrlValue;
  }

  assignToCollection(event) {
    event.target.form.action = this.assignToCollectionUrlValue;
  }

  selectedRecordingIdsValueChanged() {
    const newCollectionUrl = new URL(this.addCollectionTarget.href);
    const recordingIdParams = new URLSearchParams(newCollectionUrl.search);
    this.selectedRecordingIdsValue.forEach((id) => recordingIdParams.append('recording_ids[]', id));
    newCollectionUrl.search = recordingIdParams;
    this.addCollectionTarget.href = newCollectionUrl;
  }

  setCollectionAction() {
    const searchForm = document.getElementById('search-form');
    searchForm.action = this.assignToCollectionUrlValue;
  }
}